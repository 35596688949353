import styled, { device } from "@styled";
import quoteBG from "@data/images/bg/quote-bg.png";

export const TeamWrapper = styled.section`
  padding-top: 90px;
  padding-bottom: 65px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: url(${quoteBG});
    background-repeat: no-repeat;
    background-position: right bottom;
    opacity: 0.05;
  }
  ${device.medium} {
    padding-block-start: 46px;
  }
  ${device.large} {
    padding-block-start: 66px;
  }
`;
