import styled, { css, themeGet, device } from "@styled";
import quoteImg from "@data/images/icons/quote-team.png";

export const TeamMemberInner = styled.div`
  margin-inline: auto;
`;

export const TeamMemberImage = styled.div`
  position: relative;
  .gatsby-image-wrapper {
    width: 100%;
    border-radius: 6px;
    overflow: hidden;
  }
`;

export const TeamMemberSocialWrap = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 20px;
  right: 20px;
  padding-block: 11px;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background: #2874b3;
  background: rgb(40 116 179);
  transition: ${themeGet("transition")};
  cursor: pointer;
`;

export const TeamMemberInfo = styled.div`
  margin-block-start: 20px;
  text-align: center;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    bottom: calc(100% + 10px);
    width: 23px;
    height: 25px;
    background: url(${quoteImg});
    background-size: contain;
    background-repeat: no-repeat;
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.3));
    transform: translateX(-50%);
  }
  ${({ $layout }) =>
    ($layout === 2 || $layout === 3) &&
    css`
      text-align: center;
    `}
`;

export const TeamMemberName = styled.h5`
  ${device.medium} {
    font-size: 20px;
  }
  ${({ $layout }) =>
    $layout === 3 &&
    css`
      font-size: 18px;
      line-height: 1.78;
    `}
`;

export const TeamMemberDesignation = styled.p``;

export const TeamMemberWrap = styled.div`
  margin-top: 75px;
  ${({ $layout }) =>
    $layout === 1 &&
    css`
      ${TeamMemberInner} {
        max-width: 310px;
      }
    `}
  ${({ $layout }) =>
    $layout === 2 &&
    css`
      ${TeamMemberInner} {
        max-width: 350px;
      }
    `}
    &:hover {
    ${TeamMemberSocialWrap} {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
`;
