import styled, { device } from "@styled";
import ctaBG from "@data/images/bg/ctaBG.png";
export const SectionWrap = styled.section`
  padding: 60px 0;
  background: #f5f5f5;
  text-align: center;
  position: relative;
  ${device.medium} {
    padding: 120px;
  }
  ${device.large} {
    padding: 150px;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: url(${ctaBG});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;
    right: 0;
  }
`;

export const CtaContent = styled.div`
  position: relative;
  a {
    white-space: inherit;
    height: auto;
    line-height: initial;
    height: auto;
    padding: 15px 25px;
    ${device.small} {
      white-space: nowrap;
      line-height: 54px;
      height: 54px;
      padding: 0 25px;
    }
  }
`;
export const StyledHeading = styled.h2`
  font-size: 20px;
  color: #000;
  line-height: 1.13;
  margin-bottom: 30px;
  ${device.medium} {
    font-size: 26px;
  }
  ${device.xlarge} {
    font-size: 30px;
  }
  ${device.xxlarge} {
    font-size: 40px;
  }
`;
