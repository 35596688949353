import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import Button from "@ui/button";
import { HeadingType, MottoType } from "@utils/types";
import { SectionWrap, CtaContent, StyledHeading } from "./style";

const CtaArea = ({ data }) => {
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col>
            <CtaContent>
              {data?.headings?.[0] && (
                <StyledHeading as={data.headings[0]?.level}>
                  {data.headings[0]?.content}
                </StyledHeading>
              )}
              {data?.buttons?.map(({ id, content, ...rest }) => (
                <Button key={id} m="7px" {...rest}>
                  {content}
                </Button>
              ))}
            </CtaContent>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

CtaArea.propTypes = {
  data: PropTypes.shape({
    headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
    motto: PropTypes.shape(MottoType),
  }),
};

export default CtaArea;
